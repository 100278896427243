<template>
  <div>
    <el-form :model="formData" label-width="200px">
      <el-row>
        <el-form-item label="来源类型：">
          <el-select v-model="formData.sourceType" class="form-item">
            <el-option
                v-for="item in colorCalibrationSourceTypeList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="调拨单号：" v-if="formData.sourceType=='allot'">
          <el-input v-model="formData.orderNo" placeholder="请输入调拨单号" class="form-item"></el-input>
        </el-form-item>
        <el-form-item label="借出单号：" v-else>
          <el-input v-model="formData.orderNo" placeholder="请输入借出单号" class="form-item"></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="备件编码：">
          <el-input v-model="formData.materialNo" placeholder="请输入实物上的备件编码" class="form-item"></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="资产码：">
          <el-input ref="assetSn" v-model="formData.assetSn" placeholder="请输入或扫资产码" class="form-item"></el-input>
        </el-form-item>
      </el-row>
      <el-row v-if="showFactorySn">
        <el-form-item label="出厂SN码：">
          <el-input v-model="formData.factorySn" placeholder="请输入或扫出厂SN码" class="form-item"></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="亮度：">
          <el-radio-group v-model="formData.brightness">
            <el-radio :label="true">正常</el-radio>
            <el-radio :label="false">不良</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="校色：">
          <el-radio-group v-model="formData.calibration">
            <el-radio :label="true">正常</el-radio>
            <el-radio :label="false">不良</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="不良原因：">
          <el-input v-model="formData.ngReason" type="textarea" :rows="2" class="form-item"></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="校色结果文件：">
            <DocUploadComponent url="upload/file?type=color" accept=".zip" :sn="showFactorySn?formData.factorySn:formData.assetSn" @docChange="setFileIds" ref="fileUpload"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item>
          <el-button type="primary" @click="submit" :loading="loading">提交</el-button>
          <MessageComponent ref="commitMessage"/>
        </el-form-item>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import MessageComponent from "@/components/MessageComponent.vue";
import DocUploadComponent from "@/components/DocUploadComponent.vue";

export default {
  name: "ColorCalibration",
  components: {DocUploadComponent, MessageComponent},
  data() {
    return {
      formData: {
        sourceType: 'iqc',
        orderNo: '',
        materialNo: '',
        assetSn: '',
        factorySn: '',
        brightness: undefined,
        calibration: undefined,
        ngReason: '',
        colorFileId: ''
      },
      loading: false
    }
  },
  computed: {
    colorCalibrationSourceTypeList() {
      return this.$store.getters.getDictList('colorCalibrationSourceType') || [];
    },
    isGood() {
      return this.formData.brightness && this.formData.calibration
    },
    showFactorySn() {
      let assetSn = this.formData.assetSn || '';
      return assetSn != '' && assetSn.toUpperCase().startsWith('8S') !== true
    }
  },
  methods: {
    submit() {
      this.$refs.commitMessage.clear();

      this.formData.orderNo = this.formData.orderNo.trim()
      this.formData.materialNo = this.formData.materialNo.trim()
      this.formData.assetSn = this.formData.assetSn.trim()
      this.formData.factorySn = this.formData.factorySn.trim()

      if (!this.formData.sourceType) {
        return this.$refs.commitMessage.showError('请选择来源类型');
      }

      if (!this.formData.orderNo) {
        return this.$refs.commitMessage.showError('请输入' + (this.formData.sourceType == 'allot' ? '调拨单号' : '借出单号'));
      }

      if (!this.formData.materialNo) {
        return this.$refs.commitMessage.showError('请输入备件编码');
      }

      if (!this.formData.assetSn) {
        return this.$refs.commitMessage.showError('请输入或扫资产码');
      }

      if (this.showFactorySn) {
        if (!this.formData.factorySn) {
          return this.$refs.commitMessage.showError('请输入或扫出厂SN码');
        }

        let is8s = this.formData.factorySn.toUpperCase().startsWith('8S');
        if (!is8s) {
          return this.$refs.commitMessage.showError('出厂SN码必须以"8S"开头');
        }

      } else {
        this.formData.factorySn = this.formData.assetSn
      }

      if (this.formData.brightness !== true && this.formData.brightness !== false) {
        return this.$refs.commitMessage.showError('请选择亮度结果');
      }
      if (this.formData.calibration !== true && this.formData.calibration !== false) {
        return this.$refs.commitMessage.showError('请选择校色结果');
      }

      if (this.isGood) {
        if (!this.formData.colorFileId) {
          return this.$refs.commitMessage.showError('请上传校色结果文件');
        }
      } else {
        if (!this.formData.ngReason) {
          return this.$refs.commitMessage.showError('请输入不良原因');
        }
      }

      if (this.$socket.notOk()) {
        this.$confirm('未成功连接打印机小程序，是否继续？', '提示', {
          cancelButtonText: '取消',
          confirmButtonText: '确定',
          type: 'warning'
        }).then(() => {
          this.save()
        })
      } else {
        this.save();
      }

    },
    save() {
      this.loading = true
      this.$axios.post('colorCalibration/save', this.formData).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$refs.commitMessage.showError(res.message);
        }

        let type = res.data;
        if (this.formData.calibration && type) {
          //打印
          this.$socket.send({
            "requestId": Math.round(Math.random() * 100000000),
            "printType": "calibration",
            "printer": process.env.VUE_APP_ASSET_SN_PRINTER_NAME,
            "printVO": {
              "assetSn": this.formData.factorySn,
              "type": type
            }
          })
        }

        this.$refs.commitMessage.showSuccess('提交成功')
        this.formData.assetSn = ''
        this.formData.factorySn = ''
        this.formData.brightness = undefined
        this.formData.calibration = undefined
        this.formData.ngReason = ''
        this.formData.colorFileId = ''
        this.$refs.fileUpload.onClear();
        this.$refs.assetSn.focus();

      }, error => {
        this.loading = false;
        return this.$refs.commitMessage.showError('提交失败，' + error.message);
      });

    },
    setFileIds(fileIds) {
      if (fileIds && fileIds.length > 0) {
        this.formData.colorFileId = fileIds[0];
      } else {
        this.formData.colorFileId = '';
      }
    }
  }
}
</script>

<style scoped>
.form-item {
  width: 300px;
}
</style>